import React from 'react';
import {colors} from '../theme/tokens/colors';

export interface ArrowLinkIconProps {
  color?: keyof typeof colors;
  size?: number;
}

export const ArrowLinkIcon = React.memo<ArrowLinkIconProps>(
  ({color = 'textLow', size = 10}) => (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={colors[color] || color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6325 3.75607V11.9206L13.5 10.0738V0.5H3.94403L2.07649 2.34673H10.2015L0.5 12.0664L1.88246 13.5L11.6325 3.75607Z"
        fill={colors[color] || color}
      />
    </svg>
  ),
);
